import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import globalPaymentIcon  from  '../static/images/Group 37401.svg'
import MoneyArriveQuickly from '../static/images/Group 37402.svg'
import Paymnentfaster from '../static/images/Group 37403.svg'
import WalipayIllustration  from '../static/images/Group 37392.png'
import {useTranslation } from 'react-i18next';

const DailyUsageSection: React.FC = () => {
    const {t} = useTranslation();
    return (
        <div className="padding-top-bottom">
            <Container>
                <Row className="align-items-center">
                    <Col lg={8} xl={6} className="mb-5 mb-lg-0">
                        <h3 className="common-heading">{t('service_programme_enterprise')}</h3>
                        
                        {/* <div className="list-item mb-4">
                            <div className="img">
                                <img src={globalPaymentIcon} alt="Global Payment System"/>
                            </div>
                            <div className="content">
                                <h4>A New Global Payment System</h4>
                                <p>Lorem ipsum dolor sit a Captivate your guests with seamls, browsing experience across all devices.</p>
                            </div>
                        </div> */}

                        <div className="list-item mb-4">
                            <div className="img">
                                <img src={MoneyArriveQuickly} alt="Money Arrives Instantly"/>
                            </div>
                            <div className="content">
                                <h4>{t('money_collection')}</h4>
                                <p>{t('money_collection_description')}</p>
                            </div>
                        </div>

                        <div className="list-item">
                            <div className="img">
                                <img src={Paymnentfaster} alt="Payment Faster"/>
                            </div>
                            <div className="content">
                                <h4>{t('wali_Parternship')}</h4>
                                <p>{t('wali_partnership_description')}</p>
                            </div>
                        </div>
                    </Col>

                    <Col lg={4} xl={6}>
                        <img src={WalipayIllustration} alt="WaliPay Illustration"/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default DailyUsageSection;
