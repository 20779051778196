import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
    return (
        <footer>
            <Container>
                <Row className="d-flex flex-wrap justify-content-center justify-content-md-between align-items-center">
                    <Col xs={12} md="auto" className="mb-3 mb-md-0">
                        <div className="social-items">
                        <a href="#" className="mr-3"><FontAwesomeIcon icon={faFacebookF} /></a>
                            <a href="#" className="mr-3"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                            <a href="#" className="mr-3"><FontAwesomeIcon icon={faInstagram} /></a>
                            <a href="#"><FontAwesomeIcon icon={faYoutube} /></a>
                        </div>
                    </Col>
                    <Col xs={12} md="auto">
                        <div className="copyright">
                            <p className="mb-0">© 2023 WaliPay. All rights reserved</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
