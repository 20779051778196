import React, { useState } from 'react';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import logo from '../static/images/walipay-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { StayUpdatedModal } from './modal/Stayupdated';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

const WaliPayHeader: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const { t, i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <Navbar expand="lg" className="custom-navbar" fixed="top">
      <Container className="">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Brand href="#">
          <div>
            <img src={logo} alt="WaliPay Logo" className='logo-dimentions'/>
          </div>
        </Navbar.Brand>

        <Navbar.Collapse id="responsive-navbar-nav" className="navigation-area justify-content-center">
          <Nav className="navbar navbar-expand-lg navbar-light">
            <Nav.Link href="/">{t('home')}</Nav.Link>
            <Nav.Link href="#features">{t('feature')}</Nav.Link>
            <Nav.Link href="#enterprise">{t('enterprise')}</Nav.Link>
            {/* <Nav.Link href="/blog">{t('blog')}</Nav.Link> */}

             {/* Language Selector Dropdown */}
        <Dropdown className="language-selector">
          <Dropdown.Toggle variant="outline-primary" id="language-selector">
            {i18n.language.toUpperCase()}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => changeLanguage('en')}>EN</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('fr')}>FR</Dropdown.Item>
            {/* Add more language options as needed */}
          </Dropdown.Menu>
        </Dropdown>
          </Nav>

         
        </Navbar.Collapse>

        {/* Language Selector Dropdown */}


        {/* Add some spacing */}
        <div className="spacer"></div>

        {/* Sign Up Button - Outside Navbar Collapse for small screens */}
        <div className="">
          <Button onClick={handleShow} variant="outline-primary">{t('stay_conected')}</Button>
        </div>

        <StayUpdatedModal handleClose={handleClose} showModal={showModal} />
      </Container>
    </Navbar>
  );
}

export default WaliPayHeader;
