import React, { useState } from 'react'
import {useTranslation } from 'react-i18next';
import { Modal, Button, Form, FormGroup, FormControl, FormLabel, FormCheck } from 'react-bootstrap';
import {postContactData} from './../../helpers/rest'
import {CONTACT_URL} from './../../constant'


export const StayUpdatedModal: React.FC<StayUpdatedModalProps> = ({handleClose, showModal } ) => {
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({});
    const [showspinner, setSpinner] = useState(false);
    const handleChange = (e: any) => {
        // Update formData state as user types in the input fields
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    const handleSubmit: React.MouseEventHandler<HTMLButtonElement>  = async (e)=> {
       e.preventDefault()
       setSpinner(true);
       try { 
        console.log(process.env)
        let  result = await postContactData(formData ,new URL(CONTACT_URL ) )
        if(result.ok){
          
        }
       }catch(error){
        

       }finally{
        setSpinner(false); 
        handleClose()
       }
    } 
    return (

        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('stay_conected')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form >
                    <FormGroup controlId="formFirstName">
                        <FormLabel>{t('first_name')}</FormLabel>
                        <FormControl name="firstname" type="text" placeholder={t('first_name_place_holder')}  onChange={handleChange}/>
                    </FormGroup>
                    <FormGroup controlId="formLastName">
                        <FormLabel>{t('last_name')}</FormLabel>
                        <FormControl name="lastname" type="text" placeholder={t('last_name_place_holder')}  onChange={handleChange}/>
                    </FormGroup>

                    <FormGroup controlId="formEmail">
                        <FormLabel>{t('email')}</FormLabel>
                        <FormControl name="email" type="email" placeholder={t('email_place_holder')} onChange={handleChange} />
                    </FormGroup>

                    <FormGroup controlId="formAddress">
                        <FormLabel>{t("phone_number")}</FormLabel>
                        <FormControl name="phone" type="phone" placeholder={t("phone_number_place_holder")} onChange={handleChange} />
                    </FormGroup>

                    <FormGroup controlId="formBasicCheckbox">
                        <FormCheck name="contact_me" type="checkbox"  checked={true} label={t("agree_to_be_contacted")}  onChange={handleChange}/>
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                   {t("close")}
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                {t("send")}
                </Button>
            </Modal.Footer>
        </Modal>

    )
}



export interface StayUpdatedModalProps {
    handleClose: () => void;  
    showModal: boolean;
}
