import React from "react";
import WaliPayHeader from './WalipayHeader';
import BannerComponent from './Banner';
import FeaturesSection  from './Features';
import StepsSection from './SimpleSteps';
import DailyUsageSection from './DailyUsage'
import Footer from './Footer';
import  Container from 'react-bootstrap/Container'


export const Home: React.FC = () =>{ 



    return  ( 
        <div className="App">
           
                <WaliPayHeader  />
                <BannerComponent />
                <FeaturesSection />
                <StepsSection  />
                <DailyUsageSection />
                <Footer />
            
        </div>
    )
}