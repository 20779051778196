import { Url } from "url"

export const postContactData = function(data : any ,  url: URL) { 
    
  return fetch(url, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
}