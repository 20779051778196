import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import React from 'react';
import {Button} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';

import sewcureLogo from '../static/images/secure 1.svg';
import PlaystoreIcon from '../static/images/Icon.svg';
import AppstoreIcon from '../static/images/Vector (1).svg';
import {useTranslation } from 'react-i18next';
import { useState } from 'react';
import {StayUpdatedModal} from './modal/Stayupdated';




function BannerComponent() {
    const { t, i18n } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    return (
        <div className="main-banner">
            <Container id="banner">
                <Row className="align-items-center">
                    <Col lg={6}>
                        <div className="text-white mb-4">
                            <Image src={sewcureLogo} alt="" className="mr-2" />
                            {t('secure_payment')}
                        </div>
                        <div className="hero-title">
                           {t('purpose')}
                        </div>
                        <p>
                           {t('walipay_description')}
                        </p>

                        {/* Sign Up Button - Outside Navbar Collapse for small screens */}
                        <div className="">
                            <Button onClick={handleShow} variant="outline-light" size="lg">{t('stay_conected')}</Button>
                        </div>
                        <StayUpdatedModal handleClose={handleClose} showModal={showModal} />
                        {/* <div className="store-btn">
                            <div className="d-flex flex-column">
                                <a href="#">
                                    <Image src={PlaystoreIcon} alt="" />
                                    Play Store
                                </a>
                                <div className="rating">
                                    <span>
                                        <FontAwesomeIcon icon={solidStar}  style={{color: 'yellow'}}/>
                                        <FontAwesomeIcon icon={solidStar} style={{color: 'yellow'}}/>
                                        <FontAwesomeIcon icon={solidStar} style={{color: 'yellow'}}/>
                                        <FontAwesomeIcon icon={solidStar} style={{color: 'yellow'}}/>
                                        <FontAwesomeIcon icon={solidStar} style={{color: 'yellow'}}/>
                                    </span>
                                    <span className="text">(4.9)</span>
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <a href="#">
                                    <Image src={AppstoreIcon} alt="" />
                                    App Store
                                </a>
                                <div className="rating">
                                    <span>
                                        <FontAwesomeIcon icon={solidStar} style={{color: 'yellow'}}/>
                                        <FontAwesomeIcon icon={solidStar} style={{color: 'yellow'}}/>
                                        <FontAwesomeIcon icon={solidStar} style={{color: 'yellow'}}/>
                                        <FontAwesomeIcon icon={solidStar} style={{color: 'yellow'}}/>
                                        <FontAwesomeIcon icon={regularStar} style={{color: 'yellow'}}/>
                                    </span>
                                    <span className="text">(4.5)</span>
                                </div>

                            </div>
                        </div> */}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default BannerComponent;
