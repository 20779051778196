import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import installAppIcon from   "../static/images/Group 37400.png";
import setUpaccountIcon from "../static/images/Group 37399.png";
import securePaymentIcon  from '../static/images/Group 37398.png'

 import {useTranslation } from 'react-i18next';

const StepsSection: React.FC = () => {
    const {t} = useTranslation();
    return (
        <div className="padding-top-bottom">
            <Container  id="enterprise">
                <div className="text-center">
                    <h2 className="common-heading">{t('follow_these_steps')}</h2>
                </div>
                <Row className="align-items-center steps-row">
                    <Col sm={4}>
                        <img src={installAppIcon} alt="partners"/>
                        <div className="title">{t('partners_investers')}</div>
                        <p>{t("parteners_comment")}</p>
                    </Col>
                    <Col sm={4}>
                        <img src={setUpaccountIcon} alt="Stay updated"/>
                        <div className="title">{t('stay_updated')}</div>
                        <p>{t("stay_updated_comment")}</p>
                    </Col>
                    <Col sm={4}>
                        <img src={securePaymentIcon} alt="share our page"/>
                        <div className="title">{t('share_our_page')}</div>
                        <p>{t("share_our_page_comment")}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default StepsSection;
