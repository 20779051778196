import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-svg-core/styles.css';
import {Home} from './components/Home'
import { Blog } from './components/Blog'; 
import { Enterprise } from './components/Enterprise';

import './i18n/config'

function App() {
 return( 
  <>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/enterprise" element={<Enterprise />}/>
    </Routes>
  </>
)
}

export default App;
