import { Container, Row, Col, Image } from 'react-bootstrap';
import React from 'react';
import {useTranslation } from 'react-i18next';

import transferbalance from '../static/images/Group 37394.png'
import PaymentRequest from '../static/images/Group 37395.png'
import ReceivePayment  from '../static/images/Group 37396.png'
import withdrawFrombank  from '../static/images/Group 37397.png'
import fingerTouchPicture  from '../static/images/Group 37393.png'

function FeaturesSection() {
  const {t} = useTranslation();
  return (
    <div className="padding-top-bottom">
      <Container id ="features">
        <Row className="align-items-center">
          <Col lg={7} xl={6} className="pl-xl-5 mb-5 mb-xl-0">
            <span className="text-blue">{t('great_features')}</span>
            <h1 className="common-heading">{t('what_make_us_special')}</h1>
            <Row>
              {[
                { img: transferbalance, title: t('donations') ,  description: t('donations_description')},
                { img: PaymentRequest, title: t('prepaid_credit_card'), description: t('prepaid_credit_card_description') },
                { img: ReceivePayment, title: t('enterprise_opportunities'), description: t('enterprise_opportunities_description') },
                { img: withdrawFrombank, title: t('mobile_to_bank_transfer'), description: t('banks_transfer_description') },
              ].map((feature, index) => (
                <Col lg={6} key={index}>
                  <div className="sp-box">
                    <Image src={feature.img} alt="" />
                    <div className="title">{feature.title}</div>
                    <p>{feature.description}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col lg={5} xl={6} className="order-lg-first">
            <Image src={fingerTouchPicture} alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FeaturesSection;
